import { useViewArg } from '../../utils/viewArgs';

export type AuthenticationContext = {
    redirect: string | null;
    authenticated: boolean;
    deviceAuthenticationAllowed: boolean;
    deviceAccounts: DeviceAccount[];

    samlLoginUrl: string;
    logoutUrl: string;
};

const REDIRECT_PARAM = 'rd';

export type DeviceAccount = {
    email: string;
    name: string;
};

export function useAuthenticationContext(): AuthenticationContext {
    const redirect = useViewArg<string | null>(REDIRECT_PARAM);

    const authenticated = useViewArg<boolean>('authenticated');
    const deviceAuthenticationAllowed = useViewArg<boolean>(
        'device_authentication_allowed',
    );
    const deviceAccounts = useViewArg<DeviceAccount[]>('device_accounts');

    const samlLoginUrl = useViewArg<string>('saml_login_url');
    const logoutUrl = useViewArg<string>('logout_url');

    return {
        redirect,
        authenticated,
        deviceAuthenticationAllowed,
        deviceAccounts,
        samlLoginUrl,
        logoutUrl,
    };
}
