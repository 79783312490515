import { Button, Stack, Text } from '@kolonialno/lama';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Container from '../../components/Container';
import { useAuthenticationContext } from '../../utils/context';

export function EmailSent() {
    const [searchParams] = useSearchParams();
    const context = useAuthenticationContext();
    const navigate = useNavigate();

    const email = searchParams.get('email');

    return (
        <Container title="Login portal">
            <Stack>
                <Text
                    variant="subtitle1"
                    className="white-text"
                    alignment="center"
                >
                    Email on the way!
                </Text>
                <Text className="white-text" alignment="center">
                    If your email doesn&apos;t show up soon, check your spam
                    folder and that you typed in the correct email address.
                </Text>
                {email && (
                    <Text className="white-text" alignment="center">
                        The email was sent to <b>{email}</b>.
                    </Text>
                )}
                <Button
                    variant="primary"
                    size="m"
                    onClick={() => {
                        navigate(
                            `/authentication/login/?rd=${context.redirect}`,
                        );
                    }}
                >
                    Return to login
                </Button>
            </Stack>
        </Container>
    );
}
