import {
    Button,
    FormControl,
    Input,
    Link,
    Message,
    Spacer,
    Stack,
} from '@kolonialno/lama';
import { useRef, useState } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

import { useCSRFToken } from '../../../utils/csrf';
import { useFormErrors } from '../../../utils/viewArgs';
import Container from '../../components/Container';
import { useButtonLoading } from '../../utils/button-state';
import { useAuthenticationContext } from '../../utils/context';

export function Request() {
    const { disabled, loadingState, setLoading } = useButtonLoading();
    const csrf = useCSRFToken();
    const context = useAuthenticationContext();
    const formErrors = useFormErrors();

    const hasErrors = Object.keys(formErrors).length > 0;

    const [email, setEmail] = useState<string>('');
    const emailRef = useRef<HTMLInputElement>(null);

    return (
        <Container title="Login portal">
            {hasErrors && <Message variant="danger">Email is required</Message>}
            <Stack>
                <form
                    method="post"
                    onSubmit={() => {
                        setLoading();
                    }}
                >
                    <input
                        type="hidden"
                        name="csrfmiddlewaretoken"
                        value={csrf}
                    />

                    <Stack spacing="xs">
                        <Stack direction="horizontal" alignItems="flex-start">
                            <FormControl
                                name="email"
                                label="Email"
                                className="form-control"
                            >
                                <Input
                                    ref={emailRef}
                                    onChange={({ target }) =>
                                        setEmail(target.value)
                                    }
                                    value={email}
                                    type="email"
                                    required
                                    autoCapitalize="none"
                                    autoComplete="email"
                                />
                            </FormControl>
                        </Stack>

                        <Spacer spacing="xs" />

                        <Button
                            type="submit"
                            variant="primary"
                            size="m"
                            disabled={disabled}
                            loadingState={loadingState}
                        >
                            Send reset link
                        </Button>
                    </Stack>
                </form>
            </Stack>
            <Link
                as={ReactRouterLink}
                to={`/authentication/login/?rd=${context.redirect}`}
                className="purple-text"
            >
                Return to login
            </Link>
        </Container>
    );
}
