import { type LoadingSpinnerState } from '@kolonialno/lama';
import { useCallback, useState } from 'react';

export function useButtonLoading() {
    const [disabled, setDisabled] = useState(false);
    const [loadingState, setLoadingState] =
        useState<LoadingSpinnerState>('initial');

    const setLoading = useCallback(() => {
        setDisabled(true);
        setLoadingState('loading');
    }, [setDisabled, setLoadingState]);

    return { disabled, loadingState, setLoading };
}
