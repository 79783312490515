import {
    Button,
    FormControl,
    Input,
    Link,
    Message,
    Spacer,
    Stack,
} from '@kolonialno/lama';
import { useRef, useState } from 'react';
import { Link as ReactRouterLink, useSearchParams } from 'react-router-dom';

import { useCSRFToken } from '../../utils/csrf';
import { useFormErrors } from '../../utils/viewArgs';
import Container from '../components/Container';
import { useButtonLoading } from '../utils/button-state';
import { useAuthenticationContext } from '../utils/context';

function Login() {
    const { disabled, loadingState, setLoading } = useButtonLoading();
    const [searchParams] = useSearchParams();
    const csrf = useCSRFToken();
    const context = useAuthenticationContext();
    const formErrors = useFormErrors();

    const hasErrors = Object.keys(formErrors).length > 0;

    const [email, setEmail] = useState<string>('');
    const emailRef = useRef<HTMLInputElement>(null);

    const [password, setPassword] = useState<string>('');
    const passwordRef = useRef<HTMLInputElement>(null);

    const message = searchParams.get('message');

    return (
        <Container title="Login portal">
            {message && <Message variant="success">{message}</Message>}
            {hasErrors && (
                <Message variant="danger">
                    Incorrect username or password
                </Message>
            )}
            <Stack>
                <form
                    method="post"
                    onSubmit={() => {
                        setLoading();
                    }}
                >
                    <input
                        type="hidden"
                        name="csrfmiddlewaretoken"
                        value={csrf}
                    />
                    <input
                        type="hidden"
                        name="rd"
                        value={context.redirect || undefined}
                    />

                    <Stack spacing="xs">
                        <FormControl
                            name="username"
                            label="Email address"
                            className="form-control"
                        >
                            <Input
                                ref={emailRef}
                                onChange={({ target }) =>
                                    setEmail(target.value)
                                }
                                value={email}
                                type="email"
                                autoFocus
                                required
                                autoCapitalize="none"
                                maxLength={254}
                                autoComplete="email"
                            />
                        </FormControl>
                        <FormControl
                            name="password"
                            label="Password"
                            className="form-control"
                        >
                            <Input
                                ref={passwordRef}
                                onChange={({ target }) =>
                                    setPassword(target.value)
                                }
                                value={password}
                                type="password"
                                required
                                autoComplete="current-password"
                            />
                        </FormControl>

                        <Spacer spacing="xs" />

                        <Button
                            type="submit"
                            variant="primary"
                            size="m"
                            disabled={disabled}
                            loadingState={loadingState}
                        >
                            Log in
                        </Button>
                    </Stack>
                </form>
            </Stack>
            <Link
                as={ReactRouterLink}
                to={`/authentication/forgot-password/?rd=${context.redirect}`}
                className="purple-text"
            >
                I forgot my password
            </Link>
            <Link
                as={ReactRouterLink}
                to={`/authentication/?rd=${context.redirect}`}
                className="purple-text"
            >
                Back
            </Link>
        </Container>
    );
}

export default Login;
