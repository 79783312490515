import {
    Button,
    FormControl,
    Input,
    Link,
    Message,
    Spacer,
    Stack,
    Text,
} from '@kolonialno/lama';
import { useRef, useState } from 'react';
import { Link as ReactRouterLink, useSearchParams } from 'react-router-dom';

import { useCSRFToken } from '../../../utils/csrf';
import { useFormErrors } from '../../../utils/viewArgs';
import Container from '../../components/Container';
import { useButtonLoading } from '../../utils/button-state';
import { useAuthenticationContext } from '../../utils/context';

export function VerifyOTC() {
    const { disabled, loadingState, setLoading } = useButtonLoading();
    const [searchParams] = useSearchParams();
    const csrf = useCSRFToken();
    const context = useAuthenticationContext();
    const formErrors = useFormErrors();

    const hasErrors = Object.keys(formErrors).length > 0;

    const [oneTimeCode, setOneTimeCode] = useState<string>('');
    const oneTimeCodeRef = useRef<HTMLInputElement>(null);

    const signedChallenge = searchParams.get('signed-challenge');
    const phoneNumber = searchParams.get('phone-number');

    return (
        <Container title="Login portal">
            {hasErrors && (
                <Message variant="danger">Incorrect one-time code</Message>
            )}
            <Stack>
                <form
                    method="post"
                    onSubmit={() => {
                        setLoading();
                    }}
                >
                    <input
                        type="hidden"
                        name="csrfmiddlewaretoken"
                        value={csrf}
                    />
                    <input
                        type="hidden"
                        name="rd"
                        value={context.redirect || undefined}
                    />
                    <input
                        type="hidden"
                        name="signed_challenge"
                        value={signedChallenge || undefined}
                    />

                    <Stack spacing="xs">
                        <FormControl
                            name="one_time_code"
                            label="One-time code"
                            className="form-control"
                        >
                            <Input
                                ref={oneTimeCodeRef}
                                onChange={({ target }) =>
                                    setOneTimeCode(target.value)
                                }
                                placeholder={
                                    phoneNumber
                                        ? `Code sent to ${phoneNumber}`
                                        : undefined
                                }
                                value={oneTimeCode}
                                autoFocus
                                required
                                autoCapitalize="characters"
                                maxLength={6}
                                autoComplete="one-time-code"
                            />
                        </FormControl>

                        <Spacer spacing="xs" />

                        <Button
                            type="submit"
                            variant="primary"
                            size="m"
                            disabled={disabled}
                            loadingState={loadingState}
                        >
                            Log in
                        </Button>
                    </Stack>
                </form>
            </Stack>
            <Text className="white-text">
                Didn&apos;t receive the code?{' '}
                <Link
                    as={ReactRouterLink}
                    to={`/authentication/sms/?rd=${context.redirect}`}
                    className="purple-text"
                >
                    Back
                </Link>
            </Text>
        </Container>
    );
}
