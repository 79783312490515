import '@kolonialno/lama/dist/styles.css';
// eslint-disable-next-line import/no-unresolved
import 'vite/modulepreload-polyfill';

import './styles.scss';

import { LamaProvider } from '@kolonialno/lama';
import * as SentryIntegrations from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from 'react-router-dom';

import App from './AuthenticationApp/App';

type SentryContext = {
    SENTRY_DSN: string | null;
    ENVIRONMENT: string;
    VERSION: string;
    USER_ID: string | null;
    USER_EMAIL: string | null;
};

const sentryContext: SentryContext = JSON.parse(
    document.getElementById('sentry-context')?.textContent || '',
);

let integrations: any[] = [
    SentryIntegrations.captureConsoleIntegration({
        levels: ['error'],
    }),
    SentryIntegrations.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
    }),
];

const SENTRY_REPLAY_ENABLED = false;

if (SENTRY_REPLAY_ENABLED) {
    integrations = [
        ...integrations,
        SentryIntegrations.replayIntegration({
            maskAllText: true,
            blockAllMedia: true,
        }),
    ];
}

SentryIntegrations.init({
    dsn: sentryContext.SENTRY_DSN || undefined,
    transport: SentryIntegrations.makeBrowserOfflineTransport(
        SentryIntegrations.makeFetchTransport,
    ),
    environment: sentryContext.ENVIRONMENT,
    release: sentryContext.VERSION,
    attachStacktrace: true,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations,
    tracesSampleRate: 0,
});

SentryIntegrations.setUser({
    id: sentryContext.USER_ID || undefined,
    email: sentryContext.USER_EMAIL || undefined,
});

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <LamaProvider>
            <App />
        </LamaProvider>
    </React.StrictMode>,
);
