import * as Sentry from '@sentry/react';
import { Route, Routes } from 'react-router-dom';

import Device from './pages/Device';
import { EmailSent, Request, SetPassword } from './pages/ForgotPassword';
import Index from './pages/Index';
import Login from './pages/Login';
import NoMatch from './pages/NoMatch';
import { RequestOTC, VerifyOTC } from './pages/OneTimeCode';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export default function AppRoutes() {
    return (
        <SentryRoutes>
            <Route path="/authentication" element={<Index />} />
            <Route path="/authentication/device" element={<Device />} />
            <Route path="/authentication/login" element={<Login />} />
            <Route path="/authentication/sms" element={<RequestOTC />} />
            <Route path="/authentication/sms/verify" element={<VerifyOTC />} />
            <Route
                path="/authentication/forgot-password"
                element={<Request />}
            />
            <Route
                path="/authentication/forgot-password/email-sent"
                element={<EmailSent />}
            />
            <Route
                path="/authentication/set-password"
                element={<SetPassword />}
            />

            <Route path="*" element={<NoMatch />} />
        </SentryRoutes>
    );
}
